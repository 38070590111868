.yourListingsMainCon3 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: var(--app-beige, #f0e6d2);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  z-index: 0;
}
.yourListingsMainCon2 {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  box-sizing: border-box;

  background: var(--app-beige);
}
.yourListingsMainCon5 {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
  background: var(--app-beige);
}
.yourListingsMainCon4 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--app-orange, #ff7043);

  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.yourListingsMainCon4Action {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--app-charcoal-dark, #ff7043);
  background: var(--app-gold-yellow);

  color: var(--app-charcoal-dark, #ff7043);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.YourListingsMainCon6 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.YourListingsMainCon7 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--app-green, #3a7d44);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.YourListingsMainCon8 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--app-gold-yellow, #f4c542);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
