.yourListingsMainCon3 {
  background: var(--app-beige, #f0e6d2);
  z-index: 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 0 4px 1px #00000040;
}

.yourListingsMainCon2 {
  box-sizing: border-box;
  background: var(--app-beige);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 24px 0;
  display: flex;
}

.yourListingsMainCon5 {
  box-sizing: border-box;
  background: var(--app-beige);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px 0;
  display: flex;
  overflow: hidden scroll;
}

.yourListingsMainCon4 {
  border: 1px solid var(--app-orange, #ff7043);
  color: var(--app-orange, #ff7043);
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.yourListingsMainCon4Action {
  border: 1px solid var(--app-charcoal-dark, #ff7043);
  background: var(--app-gold-yellow);
  color: var(--app-charcoal-dark, #ff7043);
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.YourListingsMainCon6 {
  flex-wrap: wrap;
  place-content: flex-start space-between;
  align-self: stretch;
  align-items: flex-start;
  row-gap: 12px;
  padding: 0 12px;
  display: flex;
}

.YourListingsMainCon7 {
  background: var(--app-green, #3a7d44);
  color: var(--app-white, #fff);
  letter-spacing: .12px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.YourListingsMainCon8 {
  background: var(--app-gold-yellow, #f4c542);
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .12px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}
/*# sourceMappingURL=index.4caf723c.css.map */
